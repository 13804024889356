<template>
	<div class="Release bgfff br4 p32">
		<div v-if="curType == 'list'">
			<div class="nav fs16 fw-7 font-33 pb24 pr00 flex justify-between">
				<div class="tit-line flex">
					<span class="pr">我的发布</span>
					<span class="line">我的发布</span>
				</div>
				<span @click="curType = 'issue'" class="right fw-4 fs14">发布</span>
			</div>
			<div style="min-height: 600px" v-loading="listLoading" class="mt12">
				<div v-if="arrs.length > 0 || listLoading">
					<div
						@click="jumpDetail(item)"
						v-for="(item, index) in arrs"
						:key="index"
						class="pt32"
					>
						<ItemCon
							class="bgfff br4 pb24 global-hover plr6"
							style="border-bottom: 1px solid #eeeeee"
							:item="item"
							:titLine="false"
							:style="{ width: '100%' }"
						></ItemCon>
					</div>
				</div>
				<div
					v-else
					class="mt24 bgfff br4"
					style="overflow: hidden; padding-bottom: 60px"
				>
					<Empty></Empty>
				</div>
			</div>

			<!-- 分页 start -->
			<div class="page-wrap flex justify-end mt32">
				<el-pagination
					:background="true"
					layout="prev, pager, next"
					:total="total"
					:current-page="page"
					:page-size="pageSize"
					@current-change="change"
					hide-on-single-page
				>
				</el-pagination>
			</div>
			<!-- 分页 end -->
		</div>

		<!-- 发布  -->
		<div v-if="curType == 'issue'" class="issue">
			<div class="tit flex justify-between align-center">
				<div class="left">
					<!-- 面包屑 start -->
					<div class="bread flex align-center font-33 pb24">
						您现在的位置：<el-breadcrumb
							separator="/"
							separator-class="el-icon-arrow-right"
						>
							<el-breadcrumb-item
								@click.stop.prevent.native="
									(curType = 'list'), (curDetailId = ''), (againObj = {})
								"
								class="is-link"
								>我的发布</el-breadcrumb-item
							>
							<el-breadcrumb-item>发布</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
					<!-- 面包屑 end -->
				</div>
			</div>

			<ReleaseIssue
				:curType.sync="curType"
				:againObj.sync="againObj"
				@operate="issueOperate"
				:editId="curDetailId"
			></ReleaseIssue>
		</div>
		<!-- 发布  -->

		<!-- 提交成功 start -->
		<div v-if="curType == 'success'" class="success">
			<!-- 面包屑 start -->
			<div class="bread flex align-center font-33 pb24">
				您现在的位置：<el-breadcrumb
					separator="/"
					separator-class="el-icon-arrow-right"
				>
					<el-breadcrumb-item
						@click.stop.prevent.native="
							(curType = 'list'), (curDetailId = ''), (againObj = {})
						"
						class="is-link"
						>我的发布</el-breadcrumb-item
					>
					<el-breadcrumb-item>发布</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<!-- 面包屑 end -->

			<div class="text-c">
				<div class="header">
					<img src="@/assets/public/success.png" />
					<div class="mt32 fw-7 font-33 fs16">提交成功</div>
				</div>
				<div class="mt24 fs16 font-66">
					管理员政策审核中，审核成功就发布成功
				</div>
				<div class="bottom-action flex justify-center">
					<button @click="curType = 'list'">查看我的发布</button>
					<router-link to="/">首页</router-link>
				</div>
			</div>
		</div>
		<!-- 提交成功 end -->

		<!-- 详情 -->
		<div v-if="curType == 'detail'" class="detail">
			<div class="tit flex justify-between align-center">
				<div class="left">
					<!-- 面包屑 start -->
					<div class="bread flex align-center font-33 pb24">
						您现在的位置：<el-breadcrumb
							separator="/"
							separator-class="el-icon-arrow-right"
						>
							<el-breadcrumb-item
								@click.stop.prevent.native="
									(curType = 'list'), (curDetailId = ''), (againObj = {})
								"
								class="is-link"
								>我的发布</el-breadcrumb-item
							>
							<el-breadcrumb-item>详情</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
					<!-- 面包屑 end -->
				</div>
			</div>

			<ReleaseDetail
				:curType.sync="curType"
				@operate="detailOperate"
				:id="curDetailId"
			></ReleaseDetail>
		</div>
		<!-- 详情 -->
	</div>
</template>
<script>
import ItemCon from "@/components/ItemCon.vue";
import ReleaseIssue from "@/components/ReleaseIssue.vue";
import ReleaseDetail from "@/components/ReleaseDetail.vue";
export default {
	name: "Release",
	components: {
		ItemCon,
		ReleaseIssue,
		ReleaseDetail,
	},
	data() {
		return {
			curType: "list", // 当前在那里  list 列表; issue 我的发布; success 提交成功; detail 详情;
			arrs: [],
			total: 0, // 总条数
			pageSize: 15, // 每页条数
			page: 1, // 当前的页码
			listLoading: false,
			curDetailId: "", //  当前详情的 id
			againObj: {}, // 编辑的数据

			scorllTop: 0, // 距离顶部得距离
		};
	},
	watch: {
		curType: {
			handler(newVal) {
				if (newVal == "list") {
					document.documentElement.scrollTop = this.scorllTop;
					document.body.scrollTop = this.scorllTop;
				}
			},
		},
	},
	mounted() {
		window.addEventListener("scroll", this.releaseScroll);
	},
	destroyed() {
		window.removeEventListener("scroll", this.releaseScroll);
	},
	created() {
		this.getList();
	},
	methods: {
		releaseScroll() {
			if (this.curType == "list") {
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				this.scorllTop = scrollTop;
			}
		},
		// 发布页操作
		issueOperate(e) {
			let { type } = e;
			if (type == "success") {
				this.curType = "success";
				this.$pageScrollTop();
				this.againObj = {};
				this.curDetailId = "";
				this.change(1);
			}
		},
		// 详情页 操作
		detailOperate(e) {
			let { type, obj = {} } = e;
			if (type == "del") {
				this.curDetailId = "";
				this.curType = "list";
				this.getList();
				this.$pageScrollTop();
			}

			if (type == "again") {
				this.curType = "issue";
				this.againObj = obj;
			}
		},
		jumpDetail(item) {
			this.curDetailId = item.id;
			this.curType = "detail";
		},
		// 分页改变时
		change(e) {
			this.page = e;
			this.getList();
		},

		// 数据列表
		getList() {
			if (this.listLoading) {
				return false;
			}

			let { page, pageSize } = this;

			this.listLoading = true;
			this.$pageScrollTop();

			this.$API
				.issueList({
					page,
					pagesize: pageSize,
					list_rows: pageSize,
					pageSize,
				})
				.then((res) => {
					this.listLoading = false;

					if (res.code == 1) {
						this.total = res.data.total;
						this.arrs = res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
	},
};
</script>
<style scoped lang="scss">
::v-deep {
	.bread {
		.is-link {
			span {
				font-weight: 700;
				text-decoration: none;
				transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
				color: #303133;
				cursor: pointer;

				&:hover {
					color: #409eff;
				}
			}
		}
	}
}

.Release {
	@extend .w100;
	@extend .h100;
	overflow: hidden;

	.nav {
		border-bottom: 1px dashed #eeeeee;

		.right {
			cursor: pointer;
			color: #4177dd;
		}
	}

	.tit {
		border-bottom: 1px dashed #eeeeee;

		.right {
			cursor: pointer;
			color: #4177dd;
		}
	}

	.success {
		.header {
			margin-top: 40px;

			img {
				height: 80px;
				width: auto;
			}
		}

		.bottom-action {
			margin-top: 56px;

			button,
			a {
				width: 240px;
				height: 48px;
				line-height: 48px;
				background: #ff8300;
				opacity: 1;
				border-radius: 24px;
				font-size: 16px;
				font-weight: 400;
				color: #ffffff;
			}

			a {
				margin-left: 120px;
				background-color: #f6f7f9;
				color: #333333;
			}
		}
	}
}
</style>
