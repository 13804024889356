<template>
	<div class="item-con">
		<div
			v-if="isTit"
			class="title flex align-center pb16 fs16 fw-7 font-33"
			:class="{ line: titLine }"
		>
			<img class="mr16" src="@/assets/list/item-con-tip.png" />
			{{ item.category_name }}
			<span class="s-0 fw-7" v-if="item.status == 0">待审核</span>
			<span class="s-1 fw-7" v-if="item.status == 1">审核通过</span>
			<span class="s-2 fw-7" v-if="item.status == 2">审核失败</span>
		</div>
		<div class="con">
			<div class="info fs16 fw-7 font-33" :class="{ pt24: titLine }">
				{{ item.title }}
			</div>
			<div class="date-wrap mt16 flex justify-between">
				<span class="fs14 font-66" style="flex: 1"
					>发布单位：{{ item.company }}</span
				>
				<span class="fs14 font-99">{{ item.create_time }}</span>
			</div>
		</div>
		<div class="footer flex justify-between align-center mt16">
			<div class="left" v-if="item.type == 1">
				<div class="s-0" v-if="item.speed_status == 0">未开始</div>
				<div class="s-1" v-if="item.speed_status == 1">正在执行</div>
				<div class="s-2" v-if="item.speed_status == 2">已结束</div>
			</div>
			<div class="left" v-else>
				<div style="visibility: hidden">1</div>
			</div>
			<div class="right flex align-center fs14 font-33">
				<img class="mr8" src="@/assets/list/eye.png" />
				{{ item.views }}
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "ItemCon",
	data() {
		return {
			type: 0, // 发布类型 0 无起止时间; 1 有起止时间;
			speed_status: 1, // 0 未开始; 1 正在执行; 2 已结束;   依据 开始时间结束时间
			status: 1, // 0 待审核; 1 审核通过; 2 审核拒绝;
		};
	},
	props: {
		item: {
			type: Object,
		},
		// 是否需要标题
		isTit: {
			type: Boolean,
			default: () => true,
		},
		// 是否需要标题下边的线
		titLine: {
			type: Boolean,
			default: () => true,
		},
	},
};
</script>
<style lang="scss" scoped>
.item-con {
	width: 100%;
	height: 100%;

	.title {
		&.line {
			border-bottom: 1px solid #eeeeee;
		}

		img {
			width: 18px;
			height: 10px;
		}

		span {
			margin-left: auto;

			&.s-0 {
				color: #2c8fff;
			}

			&.s-1 {
				color: #0fb268;
			}

			&.s-2 {
				color: #999999;
			}
		}
	}

	.footer {
		.left {
			div {
				color: #ffffff;
				border-radius: 16px 0 16px 0;
				padding: 4px 12px;
				font-size: 12px;
				color: #ffffff;

				&.s-0 {
					background-color: #ff8300;
				}

				&.s-1 {
					background-color: #2c8fff;
				}

				&.s-2 {
					background-color: #bfbfbf;
				}
			}
		}

		.right {
			img {
				width: 20px;
				height: 20px;
			}
		}
	}
}
</style>
