<template>
	<div class="ReleaseIssue">
		<div class="container">
			<!-- 温馨提示 start -->
			<div class="g-tips fs14">
				温馨提示：请正确选择发布信息的分类，分类错误会导致信息上传不成功
			</div>
			<!-- 温馨提示 end -->

			<!-- 发布类型 start -->
			<div class="item sex flex align-center">
				<span class="item-name">发布类型</span>
				<div class="item-div">
					<el-radio-group v-model="type">
						<el-radio :label="1">有起止时间</el-radio>
						<el-radio :label="0">无起止时间</el-radio>
					</el-radio-group>
				</div>
			</div>
			<!-- 发布类型 end -->

			<!-- 分类 start -->
			<div class="item flex">
				<span class="item-name">分类</span>
				<div class="item-div">
					<el-cascader
						style="width: 100%"
						placeholder="请选择分类"
						:options="typeArrs"
						:props="{
							value: 'id',
							label: 'name',
							children: 'children',
						}"
						v-model="cate_id"
						filterable
					/>
				</div>
			</div>
			<!-- 分类 end -->

			<!-- 发布时间 start -->
			<div class="item flex">
				<span class="item-name">发布时间</span>
				<div class="item-div">
					<el-date-picker
						style="width: 100%"
						v-model="published_date"
						type="date"
						placeholder="请选择时间"
						clearable
						:editable="false"
						value-format="yyyy-MM-dd"
					>
					</el-date-picker>
				</div>
			</div>
			<!-- 发布时间 end -->

			<!-- 起止时间 start -->
			<div v-if="type == 1" class="item flex">
				<span class="item-name">起止时间</span>
				<div class="item-div">
					<el-date-picker
						style="width: 100%"
						v-model="start_date"
						type="date"
						placeholder="请选择时间"
						clearable
						:editable="false"
						value-format="yyyy-MM-dd"
						@change="endChange($event, 'start_date')"
					>
					</el-date-picker>
				</div>
			</div>
			<!-- 起止时间 end -->

			<!-- 结束时间 start -->
			<div v-if="type == 1" class="item flex">
				<span class="item-name">结束时间</span>
				<div class="item-div">
					<el-date-picker
						style="width: 100%"
						v-model="end_date"
						type="date"
						placeholder="请选择时间"
						clearable
						:editable="false"
						value-format="yyyy-MM-dd"
						@change="endChange($event, 'end_date')"
					>
					</el-date-picker>
				</div>
			</div>
			<!-- 结束时间 end -->

			<!-- 发布单位 start -->
			<div class="item flex">
				<span class="item-name">发布单位</span>
				<div class="item-div">
					<el-input v-model="company" placeholder="请输入发布单位" clearable />
				</div>
			</div>
			<!-- 发布单位 end -->

			<!-- 地址 start -->
			<div class="item flex">
				<span class="item-name">发布省份</span>
				<div class="item-div">
					<el-cascader
						style="width: 100%"
						placeholder="全国"
						:options="province"
						:props="{
							value: 'n',
							label: 'n',
							children: 'd',
							multiple: true,
						}"
						v-model="addressProvince"
						filterable
						collapse-tags
						@change="adressHandle"
					/>
				</div>
			</div>
			<!-- 地址 end -->
			<!-- 选择的市 start -->
			<div class="item flex" v-if="city.length > 3">
				<span class="item-name">发布城市</span>
				<div class="item-div">
					<el-cascader
						style="width: 100%"
						placeholder="全省"
						:options="city"
						:props="{
							value: 'n',
							label: 'n',
							children: 'd',
							multiple: true,
						}"
						v-model="addressCity"
						filterable
						collapse-tags
					/>
				</div>
			</div>

			<!-- 选择的市 end -->
			<!-- 发布政策标题 start -->
			<div class="item flex">
				<span class="item-name">发布政策标题</span>
				<div class="item-div">
					<el-input
						style="width: 100%; height: 140px"
						v-model="title"
						type="textarea"
						maxlength="100"
						placeholder="请输入标题"
						show-word-limit
						:resize="'none'"
						clearable
					></el-input>
				</div>
			</div>
			<!-- 发布政策标题 end -->

			<!-- 政策详情 start -->
			<div class="item flex">
				<span class="item-name">政策详情</span>
				<div class="item-div">
					<el-input
						style="width: 100%; height: 140px"
						v-model="body"
						type="textarea"
						placeholder="请输入详情"
						show-word-limit
						:resize="'none'"
						clearable
					></el-input>
				</div>
			</div>
			<!-- 政策详情 end -->

			<!-- 政策解读 start -->
			<div class="item flex">
				<span class="item-name">政策享受流程</span>
				<div class="item-div">
					<el-input
						style="width: 100%; height: 140px"
						v-model="policy_decode"
						type="textarea"
						placeholder="请输入政策解读"
						show-word-limit
						:resize="'none'"
						clearable
					></el-input>
				</div>
			</div>
			<!-- 政策解读 end -->

			<!-- 原文链接 start -->
			<div class="item flex">
				<span class="item-name" style="line-height: initial"
					><span style="line-height: 40px">原文链接</span> <br />
					<span class="fs12 font-99" style="line-height: initial"
						>(如果是HTTP请带上http://)</span
					>
				</span>
				<div class="item-div">
					<el-input
						style="width: 100%; height: 140px"
						v-model="external_links"
						type="textarea"
						maxlength="200"
						placeholder="请输入原文链接"
						show-word-limit
						:resize="'none'"
						clearable
					></el-input>
				</div>
			</div>
			<!-- 原文链接 end -->

			<!-- 文件附件 start -->
			<div class="item flex">
				<span class="item-name">政策申请表格</span>
				<div class="item-div flex flex-wrap">
					<div class="file-list">
						<div
							v-for="(item, index) in fileArrs"
							:key="index"
							class="mb12 flex justify-between"
						>
							政策申请表格{{ index + 1
							}}<span @click="delFiles(item, index)">删除</span>
						</div>
					</div>
					<div class="flex">
						<el-upload
							:accept="'.xls,.xlsx,.doc,.docx,.pdf'"
							class="upload-demo"
							:action="'/api' + $API.uploadFile"
							list-type="picture"
							:show-file-list="false"
							:on-success="onSuccess"
							:before-upload="beforeUpload"
							:data="{ dir: '	dir' }"
						>
							<div class="change pr00">选择文件</div>
						</el-upload>
						<div class="texy">只支持上传：xls xlsx doc docx pdf格式文件</div>
					</div>
				</div>
			</div>
			<!-- 文件附件 end -->

			<div class="btn flex justify-center">
				<el-button @click="btn" :loading="loading">{{
					editSign ? "编辑" : "发布"
				}}</el-button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "ReleaseIssue",
	props: {
		curType: {},
		editId: {
			default: () => 0,
		},
		// 重新编辑的对象
		againObj: {
			default: () => {
				return {};
			},
		},
	},
	data() {
		return {
			loading: false,
			cityArrs: [], // 地址的数据
			typeArrs: [], // 分类数据

			editSign: false,

			//  ------------------------
			// editId: 0, // 编辑的 id
			fileLoading: false, // 文件是否上传中

			type: 1, // 发布类型 0无起止时间1有起止时间  Integer[整数]
			cate_id: [], // 分类id  Integer[整数]
			title: "", // 政策标题  String[字符串]
			published_date: "", // 发布日期  String[字符串]
			start_date: "", // 起止日期  String[字符串]
			end_date: "", // 结束日期  Integer[整数]
			company: "", // 发布单位  String[字符串]
			body: "", // 政策详情  Integer[整数]
			policy_decode: "", // 政策解读  String[字符串]
			external_links: "", // 原文链接  String[字符串]
			address: "", // 地址
			addressProvince: [], //选择的省份
			addressCity: [], //选择的市
			fileArrs: [], // 文件列表
			enclosure: "", // 文件id 英文逗号拼接  Integer[整数]
			province: [], //发布省份
			city: [], //发布市
		};
	},
	async created() {
		let arr = this.$localGetItem("threeCity") || [];

		this.cityArrs = this.$replaceChildrenEmpty(arr, {
			d: "d",
		});
		this.province = JSON.parse(JSON.stringify(this.cityArrs));
		this.province.map((item) => {
			delete item.d;
			return {
				...item,
			};
		});
		//	console.log("地址", this.cityArrs);
		//	console.log("省份", this.province);
		await this.getTypeArrs();

		if (this.editId > 0 && Object.keys(this.againObj).length > 0) {
			let obj = { ...this.againObj };
			this.edit(obj);
		}
	},
	methods: {
		adressHandle(e) {
			this.city = [];
			this.addressCity = [];
			console.log(e[0][0]);
			if (e.length == 1) {
				this.cityArrs.map((item) => {
					if (item.n == e[0][0]) {
						this.city.push(...item.d);
					}
				});
			}
			this.city = this.city.map((item) => {
				delete item.d;
				return {
					...item,
				};
			});
			console.log(this.city);
		},

		edit(obj) {
			this.$emit("update:againObj", {});
			this.editSign = true;

			// id	Integer[整数]	政策id
			// title	String[字符串]	标题
			// downloads	Integer[整数]	下载数
			// views	Integer[整数]	浏览量
			// is_enclosure	Integer[整数]	是否存在附件 0否 1是
			// status	Integer[整数]	政策状态 0待审核 1审核成功 2审核失败
			// external_links	String[字符串]	原文链接
			// published_date	String[字符串]	发布日期
			// company	String[字符串]	发布单位
			// start_date	String[字符串]	开始日期
			// end_date	String[字符串]	结束日期
			// body	String[字符串]	政策详情
			// policy_decode	String[字符串]	政策解读
			// enclosure	Array[数组]	附件id
			// speed_status_text	String[字符串]	政策进度文本
			// type	Integer[整数]	是否有起止时间 0否 1是
			// is_collection	Integer[整数]	是否收藏 0否 1是
			// check_reason 拒绝原因
			// check_time 拒接时间
			// address 地址
			// one_id  // 分类 id
			// two_id  // 分类 id
			// three_id  // 分类 id
			// four_id  // 分类 id

			this.type = obj.type;
			let arr = [obj.one_id_id];
			if (obj.two_id_id) arr.push(obj.two_id_id);
			if (obj.three_id_id) arr.push(obj.three_id_id);
			if (obj.four_id_id) arr.push(obj.four_id_id);
			this.cate_id = arr;
			this.title = obj.title;
			this.published_date = obj.published_date;
			this.start_date = obj.start_date;
			this.end_date = obj.end_date;
			this.company = obj.company;
			this.body = obj.body;
			this.external_links = obj.external_links;
			this.policy_decode = obj.policy_decode;
			//	this.address = obj.address.split("-");
			this.fileArrs = obj.enclosure;
		},

		// 起止时间 结束时间改变时
		endChange(e, type) {
			let { start_date = "", end_date = "" } = this;
			let result_time = new Date(e).getTime();
			let start_date_time = new Date(start_date).getTime();
			let end_date_time = new Date(end_date).getTime();

			if (type == "start_date") {
				if (end_date && result_time > end_date_time) {
					this.end_date = "";
				}
			}

			if (type == "end_date") {
				if (start_date && result_time < start_date_time) {
					this.$message.info("结束时间需要在起止时间之后");
				}
			}
		},

		btn() {
			let {
				type,
				cate_id,
				title,
				published_date,
				start_date,
				end_date,
				company,
				body,
				policy_decode,
				external_links,
				address,
				fileArrs,
				editId,
				editSign,
				addressProvince,
				addressCity,
			} = this;
			//	address.toString().split(",");
			console.log(addressProvince.flat(Infinity).join(","));
			console.log(addressCity.flat(Infinity).join(","));
			if (cate_id.length == 0) {
				this.$message.info("请选择分类");
				return false;
			}

			if (!published_date) {
				this.$message.info("请选择发布时间");
				return false;
			}

			if (type == 1 && !start_date) {
				this.$message.info("请选择起止时间");
				return false;
			}

			if (!company || !company.toString().trim()) {
				this.$message.info("请填写发布单位");
				return false;
			}

			// if (!address) {
			// 	this.$message.info("请选择地址");
			// 	return false;
			// }

			if (!title || !title.toString().trim()) {
				this.$message.info("请填写政策标题");
				return false;
			}

			if (!body || !body.toString().trim()) {
				this.$message.info("请填写政策详情");
				return false;
			}

			if (!policy_decode || !policy_decode.toString().trim()) {
				this.$message.info("请填写政策解读");
				return false;
			}

			if (!external_links || !external_links.toString().trim()) {
				this.$message.info("请填写原文链接");
				return false;
			}

			let params = {
				type,
				cate_id: cate_id[cate_id.length - 1],
				published_date,
				start_date,
				end_date: end_date || "",
				company,
				title,
				body,
				policy_decode,
				external_links,
				city: addressCity.flat(Infinity).join(","),
				province: addressProvince.flat(Infinity).join(","),
				address: address,
				enclosure: fileArrs
					.map((item) => {
						return item.id;
					})
					.join(","),
				is_topcate: cate_id.length == 1 ? 1 : 0,
			};
			let url = "issue";
			if (editSign > 0) {
				params.id = editId;
				url = "issueEdit";
			}

			this.loading = true;
			console.log(params);
			this.$API[url](params)
				.then((res) => {
					this.loading = false;
					if (res.code == 1) {
						this.$message.success(editSign ? "编辑完成" : "发布完成");
						this.$emit("operate", { type: "success" });
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},

		// 删除文件
		delFiles(item, index) {
			this.fileArrs.splice(index, 1);
		},
		// 上传完成
		onSuccess(e) {
			console.log(e);
			this.fileArrs.push(e.data[0]);
		},
		// 上传之前
		beforeUpload(file) {
			var name = file.name.substring(file.name.lastIndexOf(".") + 1);

			const isXls = name === "xls";
			const isXlsx = name === "xlsx";
			const isDoc = name === "doc";
			const isDocx = name === "docx";
			const isPdf = name === "pdf";
			const isLt10M = file.size / 1024 / 1024 < 10;

			if (!isXls && !isXlsx && !isDoc && !isDocx && !isPdf) {
				this.$message.error(
					"上传文件只能是 doc，docx 或 xls，xlsx 或 pdf 格式!"
				);
			}

			if (!isLt10M) {
				this.$message.error("上传文件大小不能超过 10MB!");
			}

			return (isXls || isXlsx || isDoc || isDocx || isPdf) && isLt10M;
		},
		// 获取分类列表
		async getTypeArrs() {
			if (this.typeArrs.length == 0) {
				await this.$API
					.issueType()
					.then((res) => {
						if (res.code == 1) {
							this.typeArrs = this.$replaceChildrenEmpty(res.data, {
								d: "children",
							});
						} else {
							this.$message.info(res.msg);
						}
					})
					.catch((err) => {
						console.log("err", err);
					});
			}
		},
	},
};
</script>
<style scoped lang="scss">
::v-deep {
	.item-div {
		.el-textarea {
			textarea {
				height: 100%;
			}
		}

		.el-input__count {
			background-color: transparent;
		}

		.file-list {
			width: 100%;

			> div {
				background-color: #f2f8ff;
				width: 170px;
				padding: 0 12px;

				span {
					cursor: pointer;
					color: #4177dd;
				}
			}
		}

		.upload-demo {
			width: 170px;
			text-align: center;
			border-radius: 4px;
			font-size: 16px;
			color: #2c8fff;
			background-color: #f2f8ff;
			padding: 10px 0;
			line-height: initial;
		}
	}
}

.ReleaseIssue {
	@extend .w100;
	@extend .h100;

	.item {
		padding-top: 32px;
		line-height: 40px;

		.item-name {
			width: 120px;
			text-align: right;
			padding-right: 24px;
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}

		.item-div {
			width: 480px;

			.el-textarea {
				textarea {
					height: 100%;
				}
			}
		}
	}

	.container {
		padding-top: 14px;

		.g-tips {
			padding: 20px 24px;
			background-color: #fff5ea;
			color: #ff8300;
		}

		.btn {
			margin-top: 40px;

			button {
				border: none;
				padding: 0;
				text-align: center;
				width: 240px;
				height: 48px;
				line-height: 48px;
				background: #ff8300;
				border-radius: 24px;
				font-size: 16px;
				color: #ffffff;
			}
		}
	}
}
.texy {
	margin-left: 20;
}
</style>
