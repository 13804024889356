<template>
	<div class="ReleaseDetail">
		<div class="container">
			<!-- 状态 start -->

			<div class="status-wrap br8 pr00 mt24">
				<div v-if="status == 0" class="flex align-center">
					<img class="mr16" src="@/assets/imgs/detail-done-icon.png" />
					<span class="fs18 fw-7">审核中</span>
				</div>
				<div v-if="status == 1" class="flex align-center">
					<img class="mr16" src="@/assets/imgs/detail-success-icon.png" />
					<span class="fs18 fw-7">审核成功</span>
				</div>
				<div v-if="status == 2" class="flex align-center">
					<img class="mr16" src="@/assets/imgs/detail-error-icon.png" />
					<span class="fs18 fw-7">审核失败</span>
				</div>
				<div class="btn-wrap" v-if="status == 2">
					<div class="mt8 fs14 font-66">原因：{{ check_reason }}</div>
					<div class="mt8 fs14 font-66">审核时间：{{ check_time }}</div>
					<div class="btn flex align-center mt24">
						<button
							@click="operate('del')"
							class="flex align-center justify-center fs14"
						>
							删除
						</button>
						<button
							@click="operate('again')"
							class="flex align-center justify-center fs14"
						>
							重新编辑
						</button>
					</div>
				</div>
			</div>
			<!-- 状态 end -->

			<!-- 标题 start -->
			<div class="tit-wrap mt20 bgfff br8 pb24 pt24">
				<div class="tit fs20 fw-7 font-33">{{ title }}</div>

				<div class="status-1">
					<div class="tit-bot flex justify-between align-center mt12">
						<div class="left" v-if="type == 1">
							<div class="s-0" v-if="speed_status == 0">未开始</div>
							<div class="s-1" v-if="speed_status == 1">正在执行</div>
							<div class="s-2" v-if="speed_status == 2">已结束</div>
						</div>
						<div class="left" v-else>
							<div style="visibility: hidden">1</div>
						</div>
						<div v-if="status == 1" class="right flex align-center font-33">
							<div class="flex align-center">
								<img src="@/assets/list/eye.png" />
								{{ views }}
							</div>
							<div class="flex align-center">
								<img src="@/assets/imgs/detail-download-icon.png" />
								{{ downloads }}
							</div>
						</div>
					</div>
					<div class="flex mt12">
						<!-- <a
							v-if="external_links"
							target="_blank"
							:href="isHttp(external_links)"
							class="look flex align-center"
						>
							查看原文<i class="el-icon-arrow-right"></i
						></a> -->
					</div>
				</div>
			</div>
			<!-- 标题 end -->

			<div class="g-bg"></div>

			<!-- 信息 start -->
			<div class="info-wrap bgfff br8 mt16">
				<div class="fs18 fw-7 font-33 pr00">
					<div class="tit-line flex">
						<span class="pr">政策信息</span>
						<span class="line">政策信息</span>
					</div>
				</div>

				<!-- 概要信息 start -->
				<div class="border-bottom pb24">
					<div class="tit flex align-center fw-7 font-33 pt24">
						<img src="@/assets/list/item-con-tip.png" />
						概要信息
					</div>

					<div class="mt16 flex" style="align-items: flex-start">
						<span class="font-66">发布时间：</span>
						<span class="font-33" style="flex: 1">{{ published_date }}</span>
					</div>

					<div class="mt16 flex" style="align-items: flex-start">
						<span class="font-66">发布单位：</span>
						<span class="font-33" style="flex: 1">{{ company }}</span>
					</div>

					<div
						v-if="type == 1"
						class="mt16 flex"
						style="align-items: flex-start"
					>
						<span class="font-66">起止时间：</span>
						<span class="font-33" style="flex: 1">
							{{ start_date }}至{{ end_date }}</span
						>
					</div>
				</div>
				<!-- 概要信息 end -->

				<!-- 详情 start -->
				<div class="border-bottom pb32">
					<div class="tit flex align-center fw-7 font-33 pt24">
						<img src="@/assets/list/item-con-tip.png" />
						政策详情
					</div>

					<div
						v-html="body"
						class="mt24 font-66"
						style="padding-right: 120px"
					></div>
				</div>
				<!-- 详情 end -->

				<!-- 政策解读 start -->
				<div class="border-bottom pb32">
					<div class="tit flex align-center fw-7 font-33 pt24">
						<img src="@/assets/list/item-con-tip.png" />
						政策享受流程
					</div>
					<div
						v-html="policy_decode"
						class="mt24 font-66"
						style="padding-right: 120px"
					></div>
				</div>
				<!-- 政策解读 end -->

				<!-- 附件 start -->
				<div v-if="enclosure.length > 0" class="">
					<div class="tit flex align-center fw-7 font-33 pt24">
						<img src="@/assets/list/item-con-tip.png" />
						政策申请表格
					</div>

					<div class="font-33 mt24">政策申请表格下载：</div>

					<div
						@click="downFileFun(0, item.id)"
						v-for="(item, index) in enclosure"
						:key="index"
						class="flex mt24"
					>
						<button class="download br16 flex align-center justify-between">
							政策申请表格下载{{ index + 1 }}
							<img class="ml32" src="@/assets/imgs/detail-download.png" />
						</button>
					</div>
				</div>
				<!-- 附件 end -->
			</div>
			<!-- 信息 end -->
		</div>
	</div>
</template>
<script>
export default {
	name: "ReleaseDetail",
	props: {
		curType: {},
		id: {
			required: true,
		},
	},
	created() {
		this.$pageScrollTop();
		this.getData();
	},
	data() {
		return {
			delLoading: false,
			// id: 0, // 政策 id

			// type: 1, // 发布类型 0 无起止时间; 1 有起止时间;
			// speed_status: 1, // 0 未开始; 1 正在执行; 2 已结束;   依据 开始时间结束时间
			// status: 0, // 0 待审核(审核中); 1 审核通过(审核成功); 2 审核拒绝(审核失败);

			// id	Integer[整数]	政策id
			// title	String[字符串]	标题
			// downloads	Integer[整数]	下载数
			// views	Integer[整数]	浏览量
			// is_enclosure	Integer[整数]	是否存在附件 0否 1是
			// status	Integer[整数]	政策状态 0待审核 1审核成功 2审核失败
			// external_links	String[字符串]	原文链接
			// published_date	String[字符串]	发布日期
			// company	String[字符串]	发布单位
			// start_date	String[字符串]	开始日期
			// end_date	String[字符串]	结束日期
			// body	String[字符串]	政策详情
			// policy_decode	String[字符串]	政策解读
			// enclosure	Array[数组]	附件id
			// speed_status_text	String[字符串]	政策进度文本
			// type	Integer[整数]	是否有起止时间 0否 1是
			// is_collection	Integer[整数]	是否收藏 0否 1是
			// check_reason 拒绝原因
			// check_time 拒接时间
			// address 地址

			body: "",
			company: "",
			create_time: "",
			downloads: 0,
			enclosure: [],
			end_date: "",
			external_links: "",
			is_collection: 0, // 是否收藏
			is_enclosure: "",
			policy_decode: "",
			published_date: "",
			speed_status: 0,
			speed_status_text: "",
			start_date: "",
			status: 0,
			title: "",
			type: "",
			views: 0,
			check_reason: "",
			check_time: "",
			address: "",

			one_id: "", // 分类 id
			two_id: "", // 分类 id
			three_id: "", // 分类 id
			four_id: "", // 分类 id
			one_id_id: "", // 分类 id
			two_id_id: "", // 分类 id
			three_id_id: "", // 分类 id
			four_id_id: "", // 分类 id
		};
	},

	methods: {
		isHttp(str) {
			if (str.indexOf("http") == 0 || str.indexOf("https") == 0) {
				return str;
			} else {
				return "https://" + str;
			}
		},
		operate(sign) {
			if (sign == "again") {
				let {
					id,
					body,
					company,
					create_time,
					downloads,
					enclosure,
					end_date,
					external_links,
					is_collection,
					is_enclosure,
					policy_decode,
					published_date,
					speed_status,
					speed_status_text,
					start_date,
					status,
					title,
					type,
					views,
					check_reason,
					check_time,
					address,
					one_id,
					two_id,
					three_id,
					four_id,
					one_id_id,
					two_id_id,
					three_id_id,
					four_id_id,
				} = this;

				this.$emit("operate", {
					type: sign,
					obj: {
						id,
						body,
						company,
						create_time,
						downloads,
						enclosure,
						end_date,
						external_links,
						is_collection,
						is_enclosure,
						policy_decode,
						published_date,
						speed_status,
						speed_status_text,
						start_date,
						status,
						title,
						type,
						views,
						check_reason,
						check_time,
						address,
						one_id,
						two_id,
						three_id,
						four_id,
						one_id_id,
						two_id_id,
						three_id_id,
						four_id_id,
					},
				});
				return false;
			}

			if (this.delLoading) {
				return false;
			}

			this.delLoading = true;

			this.$API
				.delDetail({
					id: this.id,
				})
				.then((res) => {
					if (res.code == 1) {
						this.$message.info("政策删除成功");
						this.delLoading = false;
						this.$emit("operate", "del");
					} else {
						this.delLoading = false;
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
					this.delLoading = false;
				});
		},
		// 下载文件
		downFile(sUrl = "") {
			// try {
			//   const elemIF = document.createElement(`iframe`);
			//   elemIF.src = url;
			//   elemIF.style.display = `none`;
			//   document.body.appendChild(elemIF);
			// } catch (e) {
			//   console.log(e);
			// }

			//iOS devices do not support downloading. We have to inform user about this.

			let isChrome =
				window.navigator.userAgent.toLowerCase().indexOf("chrome") > -1;
			let isSafari =
				window.navigator.userAgent.toLowerCase().indexOf("safari") > -1;

			if (/(iP)/g.test(window.navigator.userAgent)) {
				alert(
					"Your device does not support files downloading. Please try again in desktop browser."
				);
				return false;
			}

			//If in Chrome or Safari - download via virtual link click
			if (isChrome || isSafari) {
				//Creating new link node.
				var link = document.createElement("a");
				link.href = sUrl;

				if (link.download !== undefined) {
					//Set HTML5 download attribute. This will prevent file from opening if supported.
					var fileName = sUrl.substring(sUrl.lastIndexOf("/") + 1, sUrl.length);
					link.download = fileName;
				}

				//Dispatching click event.
				if (document.createEvent) {
					var e = document.createEvent("MouseEvents");
					e.initEvent("click", true, true);
					link.dispatchEvent(e);
					return true;
				}
			}

			// Force file download (whether supported by server).
			if (sUrl.indexOf("?") === -1) {
				sUrl += "?download";
			}

			window.open(sUrl, "_self");
			return false;
		},
		// 附件下载
		downFileFun(type, file_id = "") {
			this.$API
				.downFile({
					type, // 下载方式 0单文件 1全部下载
					policy_id: this.id, // 政策id
					file_id, // 文件id 全部下载时，传空即可
				})
				.then((res) => {
					if (res.code == 1) {
						this.downFile(res.data);
					} else {
						this.$message.info(res.msg);
					}
				});
		},
		// 获取政策详情
		getData() {
			this.$API
				.detailAjax({
					id: this.id,
				})
				.then((res) => {
					// console.log("ress", res);

					if (res.code == 1) {
						Object.keys(res.data).forEach((item) => {
							switch (item) {
								case "is_collection":
									this[item] = res.data[item] == 1;
									break;
								default:
									this[item] = res.data[item];
									break;
							}
						});
					} else {
						this.$message.info(res.msg);
						setTimeout(() => {
							this.$router.back();
						}, 800);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
	},
};
</script>
<style scoped lang="scss">
.ReleaseDetail {
	@extend .w100;
	@extend .h100;

	.border-bottom {
		border-bottom: 1px solid #eeeeee;
	}

	.g-bg {
		background-color: #f8f8f8;
		height: 16px;
		width: 100%;
		// margin-left: -32px;
		position: relative;
		left: 0;
		top: 0;

		&:after {
			content: "";
			display: block;
			position: absolute;
			left: -32px;
			top: 0;
			height: inherit;
			width: 1000px;
			background-color: inherit;
		}
	}

	.container {
		.status-wrap {
			padding: 16px 24px;
			background-color: #fff5ea;

			span {
				color: #ff8300;
			}

			img {
				width: 26px;
				height: 26px;
			}

			.btn-wrap {
				padding-left: 42px;

				.btn {
					button {
						padding: 0;
						margin: 0;
						width: 120px;
						height: 40px;
						background: #f6f7f9;
						border-radius: 44px;

						&:last-child {
							margin-left: 24px;
							background: #ff8300;
							color: #ffffff;
						}
					}
				}
			}
		}

		.tit-wrap {
			.tit-bot {
				.left {
					div {
						color: #ffffff;
						border-radius: 16px 0 16px 0;
						padding: 4px 12px;
						font-size: 12px;
						color: #ffffff;

						&.s-0 {
							background-color: #ff8300;
						}

						&.s-1 {
							background-color: #2c8fff;
						}

						&.s-2 {
							background-color: #bfbfbf;
						}
					}
				}
			}

			.look {
				color: #4177dd;

				i {
					font-size: 14px;
					margin-left: 8px;
				}
			}

			.status-1 {
				.right {
					div {
						&:first-child {
							margin-right: 32px;
						}
					}

					img {
						width: 18px;
						height: 18px;
						margin-right: 8px;
					}
				}
			}
		}

		.info-wrap {
			.tit {
				img {
					margin-right: 6px;
					width: 18px;
					height: 12px;
				}
			}

			.download {
				margin: 0;
				padding: 0;
				padding: 12px 24px;
				background-color: #f2f8ff;
				color: #2c8fff;
				min-width: 400px;
				line-height: initial;

				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
}
</style>
